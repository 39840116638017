import { Controller } from "@hotwired/stimulus";

const ALPINE_STARTED = Symbol("alpine.started");

export default class extends Controller {
  static initialized = false;

  static initializing = null;

  connect() {
    if (this.constructor.initialized) return;

    if (!this.constructor.initializing) {
      this.constructor.initializing = this.constructor.initializeAlpine()
        .then(() => {
          this.constructor.initialized = true;
        })
        .catch((error) => {
          console.error("Alpine initialization failed:", error);
          this.constructor.initializing = null;
        });
    }
  }

  static async initializeAlpine() {
    await import("alpine-turbo-drive-adapter");
    const Alpine = (await import("alpinejs")).default;

    if (!window.Alpine) {
      window.Alpine = Alpine;
    }

    await import("../components/alpine_components");

    if (!window.Alpine[ALPINE_STARTED]) {
      window.Alpine.start();
      window.Alpine[ALPINE_STARTED] = true;
    }
  }
}
